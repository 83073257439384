<script lang="ts">
  import type { StoresKey } from "~/stores";
  import { getStoresContext } from "~/stores";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;
  export let styling: {
    rightLogoHref: string;
    rightLogoWidth: string;
    rightLogoMargin: string;
    rightLogoPadding: string;
    contentMaxHeight: string;
    bannerMinHeight: string;
    bgColor: string;
  };
  export let images: {
    desktopImage: any;
    mobileImage: any;
    bannerContent: any;
    rightLogo: any;
  };

  const { locale } = getStoresContext(request_store_key);
  
  // Extract image URLs and alt texts
  const desktopImage_src = images.desktopImage && "src" in images.desktopImage ? images.desktopImage.src : "";
  const mobileImage_src = images.mobileImage && "src" in images.mobileImage ? images.mobileImage.src : "";
  const bannerContent_src = images.bannerContent && "src" in images.bannerContent ? images.bannerContent.src : "";
  const bannerContent_alt = images.bannerContent && "alt" in images.bannerContent ? images.bannerContent.alt : "Banner Content";
  const rightLogo_src = images.rightLogo && "src" in images.rightLogo ? images.rightLogo.src : "";
  const rightLogo_alt = images.rightLogo && "alt" in images.rightLogo ? images.rightLogo.alt : "Right Logo";
  
  // Check if images exist
  const hasBackgroundImage = !!desktopImage_src || !!mobileImage_src;
  const hasRightLogo = !!rightLogo_src;
  const hasBannerContent = !!bannerContent_src;
</script>

{#if $locale}
  <section 
    class="banner"
    style="
      --bg-color:{styling.bgColor};
      --right-logo-width:{styling.rightLogoWidth};
      --right-logo-margin:{styling.rightLogoMargin};
      --right-logo-padding:{styling.rightLogoPadding};
      --content-max-height:{styling.contentMaxHeight};
      --banner-min-height:{styling.bannerMinHeight};
    "
  >
    <!-- Background Image Container -->
    {#if hasBackgroundImage}
      <div class="banner-background">
        <picture>
          <source srcset={desktopImage_src} media="(min-width: 992px)" />
          <img 
            src={mobileImage_src || desktopImage_src} 
            alt="Banner Background" 
            loading="lazy"
          />
        </picture>
      </div>
    {/if}
    
    <!-- Content Grid Container -->
    <div class="banner-grid">
      <!-- Top-right Logo -->
      {#if hasRightLogo}
        <div class="right-logo-container">
          <a href={styling.rightLogoHref || '#'} class="right-logo-link">
            <img 
              src={rightLogo_src} 
              alt={rightLogo_alt}
              loading="lazy"
            />
          </a>
        </div>
      {/if}
      
      <!-- Banner Content (center) -->
      {#if hasBannerContent}
        <div class="banner-content-container">
          <div class="banner-content">
            <img 
              src={bannerContent_src} 
              alt={bannerContent_alt}
              loading="lazy"
            />
          </div>
        </div>
      {/if}
    </div>
  </section>
{/if}

<style lang="scss">
  .banner {
    position: relative;
    width: 100%;
    height: auto;
    min-height: var(--banner-min-height);
    background-color: var(--bg-color);
    overflow: hidden;
    z-index: 0;
    
    /* Banner Background Image */
    .banner-background {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-height: inherit;
      z-index: 1;
      
      picture, img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        position: absolute;
      }
    }
    
    .banner-grid {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      width: 100%;
      height: 100%;
      
      /* Logo Container (top right) */
      .right-logo-container {
        grid-row: 1;
        grid-column: 1;
        justify-self: end;
        padding: clamp(8px, 2vw, 20px);
        z-index: 3;
        
        .right-logo-link {
          display: block;
          transition: opacity 0.2s ease;
          
          &:hover {
            opacity: 0.9;
          }
          
          img {
            display: block;
            width: var(--right-logo-width);
            margin: var(--right-logo-margin);
            padding: var(--right-logo-padding);
            max-width: 100%;

            @media (max-width: 768px) {
              width: calc(var(--right-logo-width) * 0.75);
            }
          }
        }
      }
      
      /* Banner Content Container (middle) */
      .banner-content-container {
        grid-row: 1 / span 2;
        grid-column: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: clamp(10px, 2vw, 20px);
        box-sizing: border-box;
        
        .banner-content {
          width: 100%;
          display: flex;
          justify-content: center;
          
          img {
            max-width: 100%;
            max-height: var(--content-max-height);
            height: auto;
            width: auto;
            object-fit: contain;
          }
        }
      }
    }
  }
  
  /* Media Queries for Responsive Layout */
  @media only screen and (max-width: 1200px) {
    .banner {
      min-height: 55vh;
      
      .banner-grid {
        .banner-content-container {
          .banner-content {
            img {
              max-height: calc(var(--content-max-height) * 0.95);
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 991px) {
    .banner {
      min-height: 50vh;
      
      .banner-grid {
        .right-logo-container {
          padding: clamp(5px, 1.5vw, 10px);
          
          img {
            width: clamp(40px, 8vw, 100px);
          }
        }
        
        .banner-content-container {
          padding: clamp(8px, 1.5vw, 15px);
          
          .banner-content {
            img {
              max-height: calc(var(--content-max-height) * 0.85);
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 768px) {
    .banner {
      min-height: 45vh;
      
      .banner-grid {
        .banner-content-container {
          .banner-content {
            img {
              max-height: calc(var(--content-max-height) * 0.75);
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 576px) {
    .banner {
      min-height: 40vh;
      
      .banner-grid {
        .right-logo-container {
          padding: clamp(3px, 1vw, 8px);
          
          img {
            width: clamp(30px, 6vw, 60px);
          }
        }
        
        .banner-content-container {
          padding: clamp(5px, 1vw, 10px);
          
          .banner-content {
            img {
              max-height: calc(var(--content-max-height) * 0.65);
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 375px) {
    .banner {
      min-height: 35vh;
      
      .banner-grid {
        .right-logo-container {
          img {
            width: clamp(25px, 5vw, 40px);
          }
        }
        
        .banner-content-container {
          .banner-content {
            img {
              max-height: calc(var(--content-max-height) * 0.55);
            }
          }
        }
      }
    }
  }
</style>